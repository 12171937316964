<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
        <el-form :inline="true">
            <el-form-item label="平台：">
                <el-select v-model="search_platform" placeholder="请选择">
                    <el-option
                    v-for="item in platformList"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="pid：">
                <el-input v-model="search_pid" placeholder=""></el-input>
            </el-form-item>
            <el-form-item>
                  <el-button type="primary" @click="seach(1)">搜索</el-button>
              </el-form-item>
        </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加</el-button>
    </div>
    <!-- 表单 -->
    <el-table 
        v-loading="loading"
        :data="configList"
        border
        style="width: 100%;">
        <el-table-column fixed label="编号" prop="id" width="50"></el-table-column>
        <el-table-column label="渠道名称" prop="remark"></el-table-column>
        <el-table-column label="pid" prop="pid"></el-table-column>
        <el-table-column label="平台">
          <template slot-scope="item">
            {{item.row.platform | platform}}
          </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
            <el-button type="danger" size="mini" @click="del(item.row.id)">删除</el-button>
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>
<!-- 修改添加弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="平台">
            <el-select v-model="platform" placeholder="请选择">
                <el-option v-for="item in platformList" :key="item.type" :label="item.name" :value="item.type">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="pid">
            <el-input v-model="pid" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="渠道名称">
            <el-input v-model="remark" placeholder=""></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem('common'))
    import * as api from "@/config/api";
    export default {
        name: 'MDMSPid',
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                dialog: false,
                configList: [],

                platformList: common.platform_list,

                id: '',
                remark: '',
                platform: '',
                pid: '',

                search_platform: '',
                search_pid: ''
            }
        },
        filters: {
            platform(val) {
                let name = ''
                if (JSON.stringify(common.platform_list).indexOf(val) != -1) {
                    common.platform_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
        },
        mounted() {
            this.getStatisPid()
        },
        methods: {
            // 获取列表
            getStatisPid(page, size) {
                api.getStatisPid({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    pid: this.search_pid,
                    platform: this.search_platform
                }, res => {
                    this.configList = res.data.list
                    this.total = Number(res.data.count)
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getStatisPid()
            },
            currentChange(val) {
                this.page = val
                this.getStatisPid()
            },
            //搜索
            seach(page) {
                this.page = page
                this.getStatisPid(page)
            },
            reset(page) {
                this.search_id = ''
                this.page = page
                this.getStatisPid(page)
            },

            // 添加选品
            add() {
                this.id = ''
                this.remark = ''
                this.platform = ''
                this.pid = ''
                this.dialog = true
            },
            // 修改、添加频道分类
            alter() {
                api.addStatisPid({
                    remark: this.remark,
                    platform: this.platform,
                    pid: this.pid,
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.dialog = false
                    this.getStatisPid()
                })
            },
            del(id) {
                this.$confirm('确认要删除该PID吗', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.delStatisPid({
                        id: id
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                        this.getStatisPid()
                    })
                }).catch(() => {})
            }
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>